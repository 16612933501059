import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Auth } from '@angular/fire/auth';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { UserActions } from 'src/app/state/actions';
import { loginLogged } from 'src/app/state/selectors/login.selectors';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { currentUser, userEmail, userId } from 'src/app/state/selectors/app.selectors';
import { LocalStoreService } from './local-store.service';

export const appGuard : CanActivateFn = async (route: ActivatedRouteSnapshot, state:RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  // const fAuth = inject(Auth);
  authService.setUrl(state.url);
  const store = inject(Store<AppState>);
  const isLogged = await firstValueFrom(store.select(loginLogged));

  console.log(isLogged);
  if (isLogged) {
    return true;
  } else {
    router.navigate(['/sessions/signin'],{queryParams: { urlRedirect: state.url }});
    return false;
  }
}

export const appGuardChild : CanActivateChildFn = async (route: ActivatedRouteSnapshot, state:RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  authService.setUrl(state.url);
  const ls = inject(LocalStoreService);
  const isLogged = ls.getItem("t");
  const createdat = new Date(ls.getItem("createdAt")).getTime();
  const now = Date.now();
  const offset = Math.ceil((now - createdat) / (60 * 1000));

  console.log(`offset: ${offset}`);
  if (isLogged && offset <= 30) {
    return true;
  } else {
    ls.clear();
    router.navigate(['/sessions/signin'],{queryParams: { urlRedirect: state.url }});
    return false;
  }
}