import { Injectable, NgZone } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { from } from "rxjs";
import { Auth, getIdToken, signInWithEmailAndPassword, signOut } from "@angular/fire/auth";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/state";
import { UserActions } from "src/app/state/actions";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private _authenticated:boolean = false;
  private _currentUrl:string;

  constructor(private ls: LocalStoreService,
    private router: Router,
    public auth:Auth,
    private store:Store<AppState>,
    private zone:NgZone) {
    
      this.auth.onAuthStateChanged(async(u) => {
        if (u) {
          // const t = await u.getIdToken(true);
          // this.ls.setItem("t",t);
          // this.ls.setItem("createdAt", Date.now());       
        }
      });
  }

  public get authenticated(): boolean {
    this._authenticated = this.auth.currentUser &&
        this.auth.currentUser.uid ? true : false;
    return this._authenticated;
  }

  signin(credentials) {
    return from(signInWithEmailAndPassword(this.auth, credentials.email, credentials.password));
  }

  signout() {
    this._authenticated = false;
    this.auth.signOut();
    this.ls.clear();
    this.router.navigateByUrl("/sessions/signin");
  }

  async idToken() {
    const idt = this.ls.getItem("t");
    const createdAt = this.ls.getItem("createdAt");
    const isExpired:boolean = ((Date.now() - createdAt) / 60 * 60 * 1000) >= 30;

    if (!idt && isExpired) {
      const token = await this.auth.currentUser.getIdToken(true);
      this.ls.setItem("t", token);
      this.ls.setItem("createdAt", Date.now());
      return token;
    }
    return idt;
  }

  setUrl(url:string) {
    this._currentUrl = url;
  }

  getRedirectUrl() {
    return this._currentUrl || "/";
  }
}
