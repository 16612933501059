import { createReducer, on } from "@ngrx/store";
import { AppState } from "..";
import { UserActions } from "../actions";

const initialState:AppState = {
    user: {
        uid: "",
        commercialName: "",
        email: "",
        name: "",
        lastname: "",
        error: ""
    },
    login: {
        loading: false,
        loadingText: "",
        logged: false
    }
};

export const appReducer = createReducer(
    initialState,
    on(UserActions.login, (state): AppState => {
        return {
            ...state,
            login: {
                loading: true,
                loadingText: "Loading...",
                logged: false
                
            }
        }
    }),
    on(UserActions.loginSuccess, (state, action): AppState => {
        return {
            ...state,
            user: {
                ...state.user,
                uid: action.userInfo.uid,
                email: action.userInfo.email,
                commercialName: "",
                lastname: "",
                name: "",
            },
            login: {
                loading: false,
                loadingText: "",
                logged: true
            }
        }
    }),
    on(UserActions.loginFail, (state, action): AppState => {
        return {
            ...state,
            user: {
                uid: "",
                name: "",
                lastname: "",
                email: "",
                commercialName: "",
                error: action.error
            },
            login: {
                loading: false,
                loadingText: action.error.message,
                logged: false
            }
        }
    }),
    on(UserActions.userInfoSuccess, (state, action): AppState => {
        return {
            ...state,
            user: {
                uid: action.userInfo.userId,
                name: action.userInfo.name,
                lastname: action.userInfo.lastname,
                email: action.userInfo.email,
                commercialName: action.userInfo.commercialName,
                error: ""
            }
        }
    }),
    on(UserActions.userInfoFail, (state, action): AppState => {
        return {
            ...state,
            user: {
                ...state.user,
                error: action.err
            }
        }
    }),
    on(UserActions.logout, (state): AppState => {
        return {
            ...state,
            login: {
                loading: false,
                logged: false,
                loadingText: ""
            },
            user: null
        }
    })
);